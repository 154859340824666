
export default {
	name: 'Main',
    data() {
        return {
			routes: [],
        }
    },
    created() {
        this.initRouters()
    },
    methods: {
		initRouters () {
			const allRoutes = []
			const project = this.$route.path.replace('/index', '')
			const list = this.$router.options.routes.filter(route => route.path.match(project))
			getRoutes(list, '/')
			this.routes = allRoutes
			function getRoutes (routes, path) {
				let routePath = path
				for (let i = 0; i < routes.length; i++) {
					const route = routes[i]
                    routePath = path + '/' + route.path
                    routePath = routePath.replace(/\/+/g, "/")
                    if (!route.children) {
                        allRoutes.push({
                            path: routePath,
                            name: route.name,
                            meta: {
                                ...route.meta
                            }
                        })
                    } else {
                        getRoutes(route.children, routePath)
                    }
				}
			}
		},
    },
};
